import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertCircle,
  ChevronDown,
  Delete,
  Link2Icon,
  MoreHorizontal,
  Pencil,
  Plane,
  Plus,
  Ship,
  Trash,
} from "lucide-react"
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { getAxiosHeaders, getShipmentStatus, OrderStatus } from "../../misc/utils";
import { baseURLs } from "../../misc/constants";
import axios from "axios";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { Alert, AlertDescription, AlertTitle, NoResults } from "../../components/ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import moment from "moment";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator } from "../../components/ui/dropdown-menu";
import { Badge } from "../../components/ui/badge";
import { Checkbox } from "../../components/ui/checkbox";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { ArrivedAtTransitWarehouseDialog, ArrivedInGhanaDialog, DealOrderedShipmentDialog, ShippedFromSupplierDialog, ShippedToGhanaDialog, UpdateAsDeliveredDialog, UpdateAsReadyForPickupDialog } from "../components/OrderUpdateForms";
import { DotsVerticalIcon } from "@radix-ui/react-icons";

export const ShipmentDetails = () => {
  const { shipmentID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [shipmentInfo, setShipmentInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [deleteLogID, setDeleteLogID] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [activeModal, setActiveModal] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage('');
  };

  const getShipmentInfo = () => {
    axios.get(`${baseURLs.API_URL}/orders/shipments/${shipmentID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setShipmentInfo(responseInfo.data.shipment_info);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedProducts(shipmentInfo.products.map((product) => product.order_item_id))
    } else {
      setSelectedProducts([])
    }
  }

  const handleSelectOrder = (orderProductId, checked) => {
    if (checked) {
      setSelectedProducts([...selectedProducts, orderProductId])
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== orderProductId))
    }
  }

  const deleteOrderLog = () => {
    axios.delete(baseURLs.API_URL + `/orders/shipments/log/${deleteLogID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;

      toggleModal('successLogDialog');
      getShipmentInfo();
      setDeleteLogID('');
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorLogDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  useEffect(() => {
    if(!shipmentID){
      navigate(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    getShipmentInfo();
  }, [])

  return (
    <React.Fragment>
      <Head title="Shipment Details" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Shipment Details</h1>
          <div className="flex gap-x-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button>
                  Update Status
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => toggleModal('shippedFromSupplierModal')}>
                  Shipped from Supplier
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => toggleModal('atTransitWarehouseModal')}>
                  Arrived at Transit Warehouse
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => toggleModal('shippedToGhanaModal')}>
                  Shipped to Ghana
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => toggleModal('arrivedInGhanaModal')}>
                  Arrived in Ghana
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/shipments`} />
          </div>
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
            <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
          </div>
          :
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="grid md:col-span-5 gap-8">
              <Card>
                <CardHeader>
                  <CardTitle className="text-md">Shipment Info</CardTitle>
                </CardHeader>
                <CardContent className="grid gap-6">
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div>
                      <span className="text-sm font-medium text-gray-500">Order Number:</span>
                      <p className="text-md font-semibold text-gray-800">{shipmentInfo.order_number}</p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-500">Shipment Type:</span>
                      <p className="text-md font-semibold text-gray-800">
                        {
                          shipmentInfo.shipment_type === 'sea' ?
                          <span className="flex items-center">
                            <Ship className="mr-2 h-4 w-4" />
                            Sea
                          </span>
                          :
                          <span className="flex items-center">
                            <Plane className="mr-2 h-4 w-4" />
                            Air
                          </span>
                        }
                      </p>
                    </div>
                    
                    <div>
                      <span className="text-sm font-medium text-gray-500">Quantity:</span>
                      <p className="text-md font-semibold text-gray-800">{shipmentInfo.quantity}</p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-500">Total Packages:</span>
                      <p className="text-md font-semibold text-gray-800">{shipmentInfo.total_packages}</p>
                    </div>

                    <div>
                      <span className="text-sm font-medium text-gray-500">Total CBM:</span>
                      <p className="text-md font-semibold text-gray-800">${shipmentInfo.cbm_amount} <small> / {shipmentInfo.cbm} CBM</small></p>
                    </div>

                    <div>
                      <span className="text-sm font-medium text-gray-500">Created By:</span>
                      <p className="text-md font-semibold text-gray-800">{shipmentInfo.created_by}</p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-500">Created:</span>
                      <p className="text-md font-semibold text-gray-800">{moment(shipmentInfo.created_at).format("Do MMM YYYY hh:mm a")}</p>
                    </div>
                  </div>
                  
                </CardContent>
              </Card> 

              <Card>
                <CardHeader>
                  <CardTitle className="text-md">Shipment Logs</CardTitle>
                </CardHeader>
                <CardContent className="grid gap-6">
                {
                  shipmentInfo.shipment_logs.length > 0 ? (
                  <ol className="relative border-l border-gray-200 dark:border-gray-700">
                    {shipmentInfo.shipment_logs.map((log, index) => {
                      let statusInfo = getShipmentStatus(log.status, shipmentInfo.shipment_type)
                      return (
                        <li key={index} className="mb-10 ml-6">
                          <span className="absolute flex items-center justify-center w-8 h-8 bg-white rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                            {statusInfo.icon}
                          </span>
                          <div className="flex justify-between">
                            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                              {statusInfo.status}
                            </h3>
                            {
                              shipmentInfo.shipment_logs.length - 1 === index && index > 0 &&
                              <Button variant="ghost" onClick={() => {
                                  setDeleteLogID(log.log_id);
                                  toggleModal("deleteLogDialog");
                                }}>
                                <Trash className="h-5 w-5 text-red-500" />
                              </Button>
                            }
                          </div>
                          <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                            {moment(log.created_at).format("Do MMM YYYY, hh:mm a")}
                          </time>

                          {
                            log.status === 'at_transit_warehouse' &&
                            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                              CBM: ${log.cbm_amount} <small>/ {log.cbm} CBM</small>
                            </p>
                          }

                          {
                            log.tracking_number &&
                            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                              Tracking Number: {log.tracking_number}
                            </p>
                          }

                          {
                            log.images?.length > 0 &&
                            <div className="gap-2">
                              {
                                log.images.map((image, index) => {
                                  return (
                                    <p className="flex gap-2 text-sm text-blue-500"><Link2Icon className="h-6 w-6 text-gray-500" /><a href={image} target="_blank">Image {index + 1}</a></p>
                                  )
                                })
                              } 
                            </div>
                          }
                        </li>
                      )

                    }
                    )}
                  </ol>
                ) : (
                  <NoResults message="No logs found" />
                )}

                {/* Modal for Image Pop-up */}
                {modalOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative">
                      <img src={currentImage} alt="Selected" className="max-h-[500px] object-contain rounded-md" />
                      <button
                        onClick={closeModal}
                        className="absolute top-2 right-2 text-white text-3xl"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                )}
                </CardContent>
              </Card>          
            </div>
            <div className="md:col-span-7">
              <Card>
                <CardHeader className="px-7 flex-row justify-between">
                  <CardTitle className="text-md content-center">{`${shipmentInfo.products.length} ${shipmentInfo.products.length > 1 ? `Products` : `Product`}`} </CardTitle>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="ml-auto" disabled={selectedProducts.length === 0}>
                        Bulk Actions <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => toggleModal("updateAsReadyForPickupModal")}>
                        Update as Ready for Pickup
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => toggleModal("updateAsDeliveredModal")}>
                        Update as Delivered
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </CardHeader>
                <CardContent>
                {
                  shipmentInfo.products.length === 0 ?
                  <NoResults message="No product found" />
                  :
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[50px]">
                          <Checkbox
                            checked={selectedProducts.length === shipmentInfo.products.length}
                            onCheckedChange={handleSelectAll}
                          />
                        </TableHead>
                        <TableHead># Order ID</TableHead>
                        <TableHead>Image</TableHead>
                        <TableHead>Product Name</TableHead>
                        <TableHead>Customer</TableHead>
                        <TableHead className="sm:table-cell">
                          Quantity
                        </TableHead>
                        <TableHead className="sm:table-cell">
                          Total
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        shipmentInfo.products.map((order, index) => {
                          return (
                            <TableRow key={index} className="cursor-pointer">
                              <TableCell>
                                <Checkbox
                                  checked={selectedProducts.includes(order.order_item_id)}
                                  onCheckedChange={(checked) =>
                                    handleSelectOrder(order.order_item_id, checked)
                                  }
                                />                                
                              </TableCell>
                              <TableCell>#{order.order_id}</TableCell>
                              <TableCell>
                                <img
                                  alt={order.product_name}
                                  className="aspect-square rounded-md object-cover"
                                  height="44"
                                  src={order.image}
                                  width="44"
                                />
                              </TableCell>
                              <TableCell>
                                <p>{order.product_name}</p>
                                <OrderStatus status={order.status} />
                              </TableCell>
                              <TableCell onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/details/${order.order_id}`)}>
                                <div className="font-medium">
                                  <div className="font-medium">{order.customer_name}</div>
                                  <div className="text-xs text-muted-foreground">
                                    {order.customer_phone_number}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell>{order.quantity}</TableCell>
                              <TableCell className="hidden md:table-cell">
                                GHS {order.total_product_cost}
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                      
                    </TableBody>
                  </Table>
                }
                </CardContent>
              </Card>
            </div>
          </div>
        }

        {
          activeModal === 'shippedFromSupplierModal' && 
          <ShippedFromSupplierDialog toggleModal={toggleModal} shipmentID={shipmentID} getShipmentDetails={getShipmentInfo} />
        }        

        {
          activeModal === 'atTransitWarehouseModal' && 
          <ArrivedAtTransitWarehouseDialog toggleModal={toggleModal} shipmentID={shipmentID} getShipmentDetails={getShipmentInfo} shipmentType={shipmentInfo.shipment_type} />
        }

        {
          activeModal === 'shippedToGhanaModal' && 
          <ShippedToGhanaDialog toggleModal={toggleModal} shipmentID={shipmentID} getShipmentDetails={getShipmentInfo} />
        }

        {
          activeModal === 'arrivedInGhanaModal' && 
          <ArrivedInGhanaDialog toggleModal={toggleModal} shipmentID={shipmentID} getShipmentDetails={getShipmentInfo} />
        }

        {
          activeModal === 'updateAsReadyForPickupModal' && 
          <UpdateAsReadyForPickupDialog toggleModal={toggleModal} productIDs={selectedProducts} getShipmentDetails={getShipmentInfo} />
        }

        {
          activeModal === 'updateAsDeliveredModal' && 
          <UpdateAsDeliveredDialog toggleModal={toggleModal} productIDs={selectedProducts} getShipmentDetails={getShipmentInfo} />
        }

        {
          activeModal === "productSuccessDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Product Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The product status has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Shipment Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The shipment status has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successLogDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deleted Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  Shipment log deleted successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "errorLogDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal("errorDialog")}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Error</AlertDialogTitle>
                <AlertDialogDescription>
                  {errorMessage}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "deleteLogDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Delete Shipment Log?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to delete this shipment log? This action cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Deleting...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={deleteOrderLog}>Delete</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

      </main>
    </React.Fragment>
  )
}

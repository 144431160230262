import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Bell,
  Container,
  FileUp,
  PackageCheck,
  PackageOpen,
  Plane,
  Plus,
  Search,
  Ship,
  ShoppingCart,
  Warehouse,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Badge } from "../../components/ui/badge";
import { getAxiosHeaders, getQueryParams, getShipmentStatus, ShipmentStatus } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Skeleton, TableSkeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { NoResults } from "../../components/ui/alert";
import { Checkbox } from "../../components/ui/checkbox";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../components/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import moment from "moment";

export const Shipments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      ordered: 0,
      shipped_from_supplier: 0,
      at_transit_warehouse: 0,
      shipped_to_ghana: 0,
      arrived_in_ghana: 0,
    },
    data: []
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [filters, setFilters] = useState({    
    page: 1,
    order_number: '',
    tracking_number: '',
    shipment_status: '',
    shipment_type: '',
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const resetFilter = () => {
    let params = {
      page: 1,
      order_number: '',
      tracking_number: '',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getShipments(params);
  }

  const filterShipments = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getShipments(filters);
  }

  // Toggle "select all" functionality
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allRowIds = tableData.data.map((shipment, index) => shipment.shipment_id);
      setSelectedRows(allRowIds);
      setCheckAll(true);
    } else {
      // If unchecked, clear all selected rows
      setSelectedRows([]);
      setCheckAll(false);
    }
  };

  // Toggle individual row selection
  const handleRowSelect = (shipment_id) => {
    if (selectedRows.includes(shipment_id)) {
      // Remove row from selection if already selected
      setSelectedRows(selectedRows.filter((row) => row !== shipment_id));
    } else {
      // Add row to selection if not already selected
      setSelectedRows([...selectedRows, shipment_id]);
    }
  };

  const navigate = useNavigate();

  const getShipments = (filters) => {
    setIsLoading(true);
    axios.get(baseURLs.API_URL + "/orders/shipments", {
      headers: getAxiosHeaders().headers,
      params: {
        page: filters.page,
        order_number: filters.order_number,
        tracking_number: filters.tracking_number,
        status: filters.shipment_status,
        type: filters.shipment_type
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 200) {
        setTableData({
          meta: responseInfo.data.meta,
          data: responseInfo.data.shipments,
        });

        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
      } else {
        setTableData({
          meta: {
            total_records: 0,
          },
          data: []
        })
      }

      currentUrl(filters);
      setIsLoading(false);
            
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setIsLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  // Delete all selected rows
  const handleDeleteSelected = () => {
    if (selectedRows.length === 0) {
        setErrorMessage(`Select at least one deal to delete`);
        toggleModal('errorDialog');
    } else {
        toggleModal('deleteDialog');
    }
  };

  const handleSingleDelete = (shipmentIDs) => {
    handleDeleteSelected([shipmentIDs]);
  };

  const confirmDelete = () => {
    checkAll ? deleteShipmentByFilter() : deleteShipmentsByIDs();    
  };

  const deleteShipmentsByIDs = () => {
    setRequesting(true);

    let _checkedBoxes = selectedRows;

    axios.delete(baseURLs.API_URL + "/orders/shipments/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        shipments: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getShipments(filters);        
        toggleModal('successDialog');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getShipments(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `shipments` : `shipment`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `shipments` : `shipment`} failed to get deleted`);
        toggleModal('errorDialog');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `shipments` : `shipment`}. The selected ${failedIDs.length > 1 ? `shipments were` : `shipment was`} not found.`);
        toggleModal('errorDialog');
      }

      setSelectedRows([]);
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const deleteShipmentByFilter = () => {
    setRequesting(false);

    axios.delete(baseURLs.API_URL + "/orders/shipments/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        order_number: filters.order_number,
        tracking_number: filters.tracking_number
      }
    })
    .then((response) => {
      getShipments(filters);
      toggleModal('successDialog');
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const currentUrl = (filters) => {
    let order_number = filters.order_number.length > 0 ? `&on=${filters.order_number}` : ``;
    let tracking_number = filters.tracking_number.length > 0 ? `&tn=${filters.tracking_number}` : ``;
    let shipment_status = filters.shipment_status !== null ? `&st=${filters.shipment_status}` : `all`;
    let shipment_type = filters.shipment_type !== null ? `&t=${filters.shipment_type}` : `all`;
    
    if( (order_number !== '' || tracking_number !== '' || 
      filters.shipment_status !== 'all' ||
      filters.shipment_type !== 'all'
    ) 
      && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${order_number}${tracking_number}${shipment_status}${shipment_type}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    navigate(url, { replace: true })
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getShipments(params)
  }

  const allSelected = selectedRows.length === tableData.data.length;

  useEffect(() => {
    setCheckAll(allSelected)
  },[allSelected])

  useEffect(() => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let order_number = queryParams.hasOwnProperty('on') ? queryParams.on : '';
    let tracking_number = queryParams.hasOwnProperty('tn') ? queryParams.tn : '';    
    let shipment_status = queryParams.hasOwnProperty('st') ? queryParams.st : 'all';    
    let shipment_type = queryParams.hasOwnProperty('t') ? queryParams.t : 'all';    

    let params = filters;
    params.page = page;
    params.order_number = order_number;
    params.tracking_number = tracking_number;
    params.shipment_status = shipment_status;
    params.shipment_type = shipment_type;

    getShipments(params);
  }, [])

  return (
    <React.Fragment>
      <Head title="Shipments" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Shipments</h1>
        </div>

        {
          isLoading ?
          <>
            <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
              <Skeleton className="h-[160px] rounded-xl" />
              <Skeleton className="h-[160px] rounded-xl" />
              <Skeleton className="h-[160px] rounded-xl" />
            </div>
            <Card x-chunk="dashboard-05-chunk-3">
              <TableSkeleton />
            </Card>
          </>
          :
          <>
            <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
              <Card x-chunk="dashboard-01-chunk-0" className="cursor-pointer" onClick={() => getShipments({...filters, shipment_status: 'all'})}>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Shipments
                  </CardTitle>
                  <Container className="h-8 w-8 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-3xl font-bold">{tableData.meta.total_records}</div>
                </CardContent>
              </Card>
              <Card x-chunk="dashboard-01-chunk-1" className="cursor-pointer" onClick={() => getShipments({...filters, shipment_status: 'at_transit_warehouse'})}>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    At Transit Warehouse
                  </CardTitle>
                  <Warehouse className="h-8 w-8 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-3xl font-bold">{tableData.meta.at_transit_warehouse}</div>
                </CardContent>
              </Card>
              <Card x-chunk="dashboard-01-chunk-2" className="cursor-pointer" onClick={() => getShipments({...filters, shipment_status: 'shipped_to_ghana'})}>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Shipped to Ghana</CardTitle>
                  <Ship className="h-8 w-8 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-3xl font-bold">{tableData.meta.shipped_to_ghana}</div>
                </CardContent>
              </Card>
            </div>

            <Card x-chunk="dashboard-05-chunk-3">
              <CardHeader className="px-7 flex-row justify-between">
                <CardTitle className="text-lg content-center">{tableData.meta.total_records} {tableData.meta.total_records > 1 ? `Shipments` : `Shipment`}</CardTitle>
                <Button variant="outline" onClick={() => toggleModal("filterModal")}><Search className="w-4 h-4 mr-2"  />Filter</Button>
              </CardHeader>
              <CardContent>
                {
                  tableData.data.length === 0 ?
                  <NoResults message="No shipment found" />
                  :
                  <>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>
                            <Checkbox checked={allSelected} onCheckedChange={handleSelectAll} />
                          </TableHead>
                          <TableHead>Order Number</TableHead>
                          <TableHead>Status</TableHead>
                          <TableHead>Quantity</TableHead>
                          <TableHead className="hidden sm:table-cell">
                            Shipment Type
                          </TableHead>
                          <TableHead className="hidden sm:table-cell">
                            Created
                          </TableHead>
                          <TableHead>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant="ghost">
                                  <DotsVerticalIcon className="h-5 w-5" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <DropdownMenuItem className="text-red-500" onClick={handleDeleteSelected}>
                                  Delete Selected
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {
                          tableData.data.map((data, index) => {
                            const isSelected = selectedRows.includes(data.shipment_id);
                            let statusInfo = getShipmentStatus(data.status, data.shipment_type)
                            return (
                              <TableRow key={index} className="cursor-pointer">
                                <TableCell>
                                  <Checkbox
                                    checked={isSelected}
                                    onCheckedChange={() => handleRowSelect(data.shipment_id)}
                                  />
                                </TableCell>
                                <TableCell onClick={() => navigate(`${process.env.PUBLIC_URL}/shipments/details/${data.shipment_id}`)}>
                                  <div>{data.order_number}</div>
                                </TableCell>
                                <TableCell className="hidden sm:table-cell capitalize font-bold">
                                  <ShipmentStatus status={statusInfo.status} />
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">
                                  <b>{data.quantity}</b>
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                  {
                                    data.shipment_type === 'sea' ?
                                    <span className="flex items-center">
                                      <Ship className="mr-2 h-4 w-4" />
                                      Sea
                                    </span>
                                    :
                                    <span className="flex items-center">
                                      <Plane className="mr-2 h-4 w-4" />
                                      Air
                                    </span>
                                  }
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                  {moment(data.created_at).format("Do MMM YYYY hh:mm a")}
                                </TableCell>
                                <TableCell>
                                  <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                      <Button variant="ghost">
                                        <DotsVerticalIcon className="h-5 w-5" />
                                      </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                      <DropdownMenuItem onClick={() => navigate(`${process.env.PUBLIC_URL}/shipments/details/${data.shipment_id}`)}>
                                        View Details
                                      </DropdownMenuItem>
                                      <hr/>
                                      <DropdownMenuItem className="text-red-500" onClick={() => handleSingleDelete(data.shipment_id)}>
                                        Delete
                                      </DropdownMenuItem>
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                    <div className="mt-5 justify-center">
                      <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                    </div>
                  </>
                }
              </CardContent>
            </Card>
          </>
        }
        {
          activeModal === "deleteDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {selectedRows.length === 1 ? "Delete Shipment" : "Delete Selected Shipments"}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {selectedRows.length === 1
                    ? "Are you sure you want to delete this shipment? This action cannot be undone."
                    : "Are you sure you want to delete all selected shipments? This action cannot be undone."}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Deleting...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={confirmDelete}>Confirm</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deleted Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  Shipment(s) deleted successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "errorDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal("errorDialog")}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Error</AlertDialogTitle>
                <AlertDialogDescription>
                  {errorMessage}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === 'filterModal' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent>
              <DialogHeader className="mb-4">
                <DialogTitle>Filter Shipments</DialogTitle>
              </DialogHeader>
                <div className="space-y-4">
                  <div className="grid gap-2">
                    <Label htmlFor="order_number">Order Number</Label>
                    <Input
                      id="order_number"
                      name="order_number"
                      type="text"
                      placeholder="Enter an order number"
                      onChange={onInputChange}
                      defaultValue={filters.order_number}
                    />
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="tracking_number">Tracking Number</Label>
                    <Input
                      id="tracking_number"
                      name="tracking_number"
                      type="text"
                      placeholder="Enter a tracking number"
                      onChange={onInputChange}
                      defaultValue={filters.tracking_number}
                    />
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="shipment_status">Shipment Status</Label>
                    <select 
                      name="shipment_status"
                      className="rounded-md border border-gray-300 p-2"
                      onChange={(e) => onSelectChange(e.target.value, "shipment_status")} 
                      value={filters.shipment_status}>
                      <option key="all" value="all">All</option>
                      <option key="ordered" value="ordered">Ordered</option>
                      <option key="shipped_from_supplier" value="shipped_from_supplier">Shipped from Supplier</option>
                      <option key="at_transit_warehouse" value="at_transit_warehouse">Arrived at Transit Warehouse</option>
                      <option key="shipped_to_ghana" value="shipped_to_ghana">Shipped to Ghana</option>
                      <option key="arrived_in_ghana" value="arrived_in_ghana">Arrived in Ghana</option>
                    </select>
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="shipment_type">Shipment Type</Label>
                    <select 
                      name="shipment_type"
                      className="rounded-md border border-gray-300 p-2"
                      onChange={(e) => onSelectChange(e.target.value, "shipment_type")} 
                      value={filters.shipment_type}>
                      <option key="all" value="all">All</option>
                      <option key="air" value="air">Air</option>
                      <option key="sea" value="sea">Sea</option>
                    </select>
                  </div>
                </div>

                {/* Dialog Footer */}
                <DialogFooter>
                  <Button onClick={(ev) => { ev.preventDefault(); filterShipments();} }>Apply Filter</Button>
                  {
                    hasFilters &&
                    <Button className="ms-3" variant="secondary" onClick={(ev) => { ev.preventDefault(); resetFilter();} }>
                      Reset Filter
                    </Button>
                  }
                </DialogFooter>
            </DialogContent>
          </Dialog>
        }
      </main>
    </React.Fragment>
  )
}

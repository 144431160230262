import React, { useState } from "react";
import { format } from "date-fns";
import { CalendarIcon, ClockIcon } from "lucide-react";

import { cn } from "../../lib/utils";
import { Button } from "../../components/ui/button";
import { Calendar } from "../../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Input } from "../../components/ui/input";

export const DatePicker = ({ field }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !field.value && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2" />
          {field.value ? format(new Date(field.value), "do LLL yyyy") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={field.value ? new Date(field.value) : null}
          onSelect={(date) => field.onChange(date)}
          className="rounded-md border"
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export const DateTimePicker = ({ field }) => {
  const [selectedTime, setSelectedTime] = useState(
    field.value ? format(new Date(field.value), "HH:mm") : ""
  );

  const handleDateChange = (date) => {
    if (!date) return;

    const [hours, minutes] = selectedTime.split(":").map(Number);
    const updatedDate = new Date(date);
    updatedDate.setHours(hours || 0);
    updatedDate.setMinutes(minutes || 0);

    field.onChange(updatedDate);
  };

  const handleTimeChange = (e) => {
    const time = e.target.value;
    setSelectedTime(time);

    if (field.value) {
      const updatedDate = new Date(field.value);
      const [hours, minutes] = time.split(":").map(Number);
      updatedDate.setHours(hours || 0);
      updatedDate.setMinutes(minutes || 0);
      field.onChange(updatedDate);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !field.value && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2" />
          {field.value ? (
            format(new Date(field.value), "do LLL yyyy, hh:mm a")
          ) : (
            <span>Pick date & time</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-4 space-y-4">
        <Calendar
          mode="single"
          selected={field.value ? new Date(field.value) : null}
          onSelect={handleDateChange}
          className="rounded-md border"
          initialFocus
        />
        <div className="flex items-center space-x-2">
          {/* <ClockIcon className="w-4 h-4 text-muted-foreground" /> */}
          <Input
            type="time"
            value={selectedTime}
            onChange={handleTimeChange}
            className="w-[120px]"
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

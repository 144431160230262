import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  CircleX,
  Cross,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { getAxiosHeaders, getAxiosUploadHeaders } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { ProductSelect } from "../components/ProductSelect";
import { DatePicker, DateTimePicker } from "../components/DatePicker";
import moment from "moment";

export const AddDeal = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      product_id: "",
      start_date: "",
      end_date: "",
      service_rate: ""
    },
  });

  const { handleSubmit, control, formState: { errors }, setError } = methods;

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

  
    axios.post(baseURLs.API_URL + "/deals/add", {
      product_id: data.product_id,
      start_date: moment(data.start_date).format("YYYY-MM-DD HH:mm"),
      end_date: moment(data.end_date).format("YYYY-MM-DD HH:mm"),
      service_rate: data.service_rate
    }, getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
        let dealID = responseInfo.data.deal_id;
  
        navigate(`${process.env.PUBLIC_URL}/deals/details/${dealID}`);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data.errors;
          setErrorMessage(error.response.data.error);
  
          // Check for specific field errors and set them
          if (responseErrors.product) {
            setError('product', {
              type: 'server',
              message: responseErrors.product
            });
          }
  
          if (responseErrors.start_date) {
            setError('start_date', {
              type: 'server',
              message: responseErrors.start_date
            });
          }

          if (responseErrors.end_date) {
            setError('end_date', {
              type: 'server',
              message: responseErrors.end_date
            });
          }
  
          if (responseErrors.service_rate) {
            setError('service_rate', {
              type: 'server',
              message: responseErrors.service_rate
            });
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };  

  return (
    <React.Fragment>
      <Head title="Add Deal" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Create a New Deal</h1>
          <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/deals`} />
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
              <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
                {
                  errorMessage &&
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {errorMessage}
                    </AlertDescription>
                  </Alert>
                }
                <Card>
                  <CardContent className="pt-6 grid gap-6">
                    {/* product Field */}
                    <FormField
                      name="product_id"
                      control={control}
                      rules={{
                        required: "Product is required",
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Product</FormLabel>
                          <FormControl>
                            <ProductSelect 
                              field={field}
                              onChange={(value) => field.onChange(value)}
                            />
                          </FormControl>
                          {errors.product_id && (
                            <FormMessage>{errors.product_id.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                    {/* Start Date & End Date */}
                    <div className="grid grid-cols-2 gap-6">
                      {/* Start Date */}
                      <FormField
                        name="start_date"
                        control={control}
                        rules={{
                          required: "Start date is required",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Start Date</FormLabel>
                            <FormControl>
                              <DateTimePicker field={field} />
                            </FormControl>
                            {errors.start_date && <FormMessage>{errors.start_date.message}</FormMessage>}
                          </FormItem>
                        )}
                      />

                      {/* End Date */}
                      <FormField name="end_date" control={control}
                        rules={{
                          required: "End date is required",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>End Date</FormLabel>
                            <FormControl>
                              <DateTimePicker field={field} />
                            </FormControl>
                            {errors.end_date && <FormMessage>{errors.end_date.message}</FormMessage>}
                          </FormItem>
                        )}
                      />
                    </div>

                    {/* Service Rate */}
                    <div className="grid grid-cols-2 gap-6">
                      <FormField name="service_rate" control={control}
                        rules={{
                          required: "Service rate is required",
                          validate: {
                            isPercentage: (value) =>
                              value >= 0 && value <= 100
                                ? true
                                : "Service rate must be a percentage (0-100)",
                          },
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Service Rate</FormLabel>
                            <FormControl>
                              <div className="relative flex items-center">
                                <Input
                                  {...field}
                                  type="number"
                                  step="0.01"
                                  className="pr-10" // Add space for the suffix
                                />
                                <span className="absolute right-3 text-gray-500 pointer-events-none">
                                  %
                                </span>
                              </div>
                            </FormControl>
                            {errors.service_rate && <FormMessage>{errors.service_rate.message}</FormMessage>}
                          </FormItem>
                        )}
                      />
                    </div>
                    
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className="pt-6">
                    <Button type="submit" className="w-full" disabled={requesting}>
                      {requesting ? "Submitting..." : "Submit"}
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </div>
          </form>
        </FormProvider>
      </main>
    </React.Fragment>
  )
}

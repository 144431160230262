import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { getAxiosHeaders } from "../../misc/utils";

const CategorySelect = ({ field, onChange }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await axios.get(`${baseURLs.API_URL}/products/category`, {
          headers: getAxiosHeaders().headers,
        });

        if (response.status === 200) {
          setCategories(response.data.data.category || []);
        } else {
          setError("Failed to load categories");
        }
      } catch (err) {
        setError("Failed to load categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    onChange(selectedCategory); // Notify parent component
  };

  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Select a Category
      </label>
      <select
        {...field}
        onChange={handleCategoryChange}
        className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {loading && <option>Loading...</option>}
        {!loading && categories.length === 0 && (
          <option>No categories available</option>
        )}
        {!loading && categories.length > 0 && (
          <>
            <option value="" disabled selected>
              Choose a category
            </option>
            {categories.map((category, index) => (
              <option key={category.category_id} value={category.category_name}>
                {category.category_name}
              </option>
            ))}
          </>
        )}
      </select>
      {error && <p className="text-red-500 mt-1 text-sm">{error}</p>}
    </div>
  );
};

export default CategorySelect;
